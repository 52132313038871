/* eslint-disable */
import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Lingallery from 'lingallery';
import OneSignalVue from 'onesignal-vue'
import "vue-search-select/dist/VueSearchSelect.css";
import VueSession from "vue-session/index.esm";
import VueGlobalVariable from "vue-global-var";
import axios from 'axios'
import VueAxios from 'vue-axios'
import firebase from 'firebase/app';
import 'firebase/auth';
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
import vueEsign from 'vue-esign'
import panZoom from 'vue-panzoom'
import { VueEditor } from "vue2-editor";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from 'vue-apexcharts'

const firebaseConfig = {
  apiKey: "AIzaSyBm6iZaXKWJsoHwRUkJOCjupFgizNvUf0k",
  authDomain: "bidbike-app.firebaseapp.com",
  projectId: "bidbike-app",
  storageBucket: "bidbike-app.appspot.com",
  messagingSenderId: "498368526895",
  appId: "1:498368526895:web:d8912e708cf6131b9e24da",
  measurementId: "G-96XWSDQE4V",
};

firebase.initializeApp(firebaseConfig);

Vue.prototype.$firebase = firebase;
Vue.use(VueAxios, axios);
Vue.use(VueSession)
Vue.use(VModal)
Vue.use(vueEsign)
Vue.use(panZoom)
Vue.use(OneSignalVue);
Vue.use(VueSweetalert2)
Vue.use(VueApexCharts)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
Vue.component('lingallery', Lingallery);
Vue.use(VueGlobalVariable, {
  globals: {
    version: "1.0",
    api_url:(process.env.NODE_ENV === "development") ? "https://devapi.bidbike.es": "https://devapi.bidbike.es",
    light_blue: "#0fe8ef",
    dark_blue: "#1065c2"
  }
});
new Vue({
  router,
  store,
  vuetify,
  beforeMount() {
    /*this.$OneSignal.init({
      appId: "241d0940-1fa3-4d7e-9c5a-e5e103495c0c",
      safari_web_id: "web.onesignal.auto.42873e37-42b9-4e5d-9423-af83e9e44ff4",
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: true,
      },
    });*/
  },
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
